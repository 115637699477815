import React from "react";
import _get from "lodash.get";
import { Col, Grid, Heading, Row } from "pws-design-system/design-system";
import PageModel from "../models/prismic/page";
import RichText from "../react/common/components/rich-text";
import Slices from "../react/common/components/slices";
import UsePreviewDataContainer from "../react/common/hooks/stores/usePreviewDataStore";
import PageLayout from "../react/common/layouts/PageLayout";
import { Providers } from "../react/common/layouts/Layout";

const UnpublishedPageDisplay = props => {
  const [previewData] = UsePreviewDataContainer.useContainer();
  const data = _get(previewData, "pageData.prismicPage");
  const pageModel = new PageModel(data, "page");
  return (
    <PageLayout>
      <Grid pb={[null, null, 6]}>
        <Row>
          <Col sm={12} lg={12}>
            {pageModel.has("title") && (
              <Heading as="h1" variant="hero" mb={[4, null, 5]}>
                {pageModel.title}
              </Heading>
            )}
            {pageModel.has("content") && <RichText markup={pageModel.content} />}
            {pageModel.has("slices") && <Slices slices={pageModel.slices} />}
          </Col>
        </Row>
      </Grid>
    </PageLayout>
  );
};

function UnpublishedPage(props) {
  return (
    <Providers>
      <UnpublishedPageDisplay {...props} />
    </Providers>
  );
}

export default UnpublishedPage;
